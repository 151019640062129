<template>
	<div class="wrapper">
		<vxe-toolbar>
			<template v-slot:buttons>
				<vxe-button type="submit" icon="el-icon-plus" size="mini"  @click="goPage('add')"></vxe-button>
			</template>
		</vxe-toolbar>
		<!-- <div class="scroll-box">
			<div class="search">
			<vxe-form title-align="right" title-width="120" title-colon :data="searchData" @submit="searchEvent" @reset="searchReset">
				<vxe-form-item title="姓名" span="8">
					<vxe-input v-model="searchData.serviceOldPersonName" placeholder="请输入姓名" clearable></vxe-input>
				</vxe-form-item>
				<vxe-form-item title="联系方式" span="8">
					<vxe-input v-model="searchData.serviceOldPersonTel" placeholder="请输入联系方式" clearable></vxe-input>
				</vxe-form-item>
				<vxe-form-item align="center" span="24">
					<vxe-button type="submit" status="my-orange" icon="fa fa-check">查询</vxe-button>
					<vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
				</vxe-form-item>
			</vxe-form>
		</div> -->
		<div class="table">
			<vxe-grid border stripe resizable auto-resize :columns="tableColumn" :data="tableData"  ref="xGrid1" header-align="center">
				<template v-slot:name="{ row }">
					<p  v-if="searchData.serviceOldPersonId==''" class="link-a" @click="goPage('personDetail', row)">{{ row.serviceOldPersonName }}</p>
					<p  v-if="searchData.serviceOldPersonId!=''" class="link-a" @click="goPage('personDetail', row)">{{ row.serviceWorkPersonName }}</p>

				</template>
				<template v-slot:telephone="{row}">
					<p v-if="searchData.serviceOldPersonId==''">{{ row.serviceOldPersonTel}}</p>
					<p v-if="searchData.serviceOldPersonId!=''">{{ row.serviceWorkPersonTel }}</p>
					
				</template>
				<template v-slot:time="{row}">
					<p>{{ row.serviceBeginTime }}</p>
					<p  style="color:red">{{ row.serviceEndTime }}</p>
				</template>
				
				<template v-slot:operation="{ row }">
					<vxe-button type="text" status="primary" v-if="row.ismore" @click="goPage('edit', row)">修改</vxe-button>
					<vxe-button type="text" status="primary" v-if="!row.ismore" @click="goPageDetai('xiangqing', row)">详情</vxe-button>
					<vxe-button type="text" status="primary"  v-show="row.status == '135_1'&&!row.ismore" @click="callBack(row)">回访</vxe-button>
					<vxe-button type="text"  icon="el-icon-plus" size="mini"  @click="row.ismore=true" v-if="!row.ismore"></vxe-button>
					<vxe-button type="text" status="primary" v-if="row.ismore" @click="remove(row.id)">删除</vxe-button>
					<vxe-button type="text"  size="mini" icon="el-icon-minus" @click="row.ismore=false" v-if="row.ismore"></vxe-button>
				</template>
			</vxe-grid>
		</div>
		<!-- 分页 -->
		<div class="page">
			<vxe-pager :current-page.sync="tablePage.currentPage" :page-size.sync="tablePage.pageSize" :page-sizes="tablePage.pageSizes"
			 :total="tablePage.totalResult" :layouts="layouts" @page-change="pageChange"></vxe-pager>
		</div>
		</div>
		
	</div>
</template>

<script>
	import {
		mapState,
		mapActions,
	} from "vuex";
	export default {
		props: {
			//老人id
			serviceOldPersonIdProp: {
				type: String,
				default: ''
			},
			//企业id
			serviceOrgIdProp: {
				type: String,
				default: ''
			},
			//从业人员di
			serviceWorkPersonIdProp: {
				type: String,
				default: ''
			},
  		},
		data() {
			return {
				loading: false,
				seachStatus: false,
				searchData: {
					serviceOldPersonId:'',
					serviceOldPersonName:'',
					serviceOldPersonTel:'',
					serviceOrgId:'',
					serviceWorkPersonId:'',
				},
				// 分页的参数配置
				tablePage: {
					totalResult: 0,
					currentPage: 1,
					pageSize: 10,
					align: "left",
					pageSizes: [10, 20, 50, 100, 500, 1000],
					perfect: true
				},
				tableColumn: [{
						type: 'seq',
						title: '序号',
						width: 60,
						align: "center"
					},
					{
						field: 'serviceOrgName',
						title: '服务机构',
						// showOverflow: true,
						align: "center",
					},
					{
						field: 'disinfectItems',
						title: '消杀物品',
						// showOverflow: true,
						align: "center",
						// minWidth: "200"
					},
					{
						field: 'disinfectAreas',
						title: '消杀区域',
						// showOverflow: true,
						align: "center",
						// minWidth: "200"
					},
										{
						field: 'serviceWorkPersonName',
						title: '工作人员',
						// showOverflow: true,
						align: "center",
						// minWidth: "200"
					},					{
						field: 'serviceItemName',
						title: '服务项目',
						// showOverflow: true,
						align: "center",
						// minWidth: "200"
					},
										{
						field: 'status',
						title: '状态',
						showOverflow: true,
						align: "center",
						// minWidth: "200"
					},
					{
						field: 'serviceTime',
						title: '消杀时间',
						showOverflow: true,
						align: "center",
						// minWidth: "100"
					},
					// {
					// 	title: '服务起止时间',
					// 	align: "center",
					// 	slots: {
					// 		default: 'time'
					// 	}
					// },
					{
						title: '操作',
						width: '200',
						fixed: 'right',
						
						// showOverflow: true,
						slots: {
							default: 'operation'
						},
					}
				],
				tableData: [],
			}
		},
		computed: {
			...mapState([
				"layouts"
			]),
		},
		created() {

			this.searchData.serviceWorkPersonId = this.serviceWorkPersonIdProp
			this.searchData.serviceOrgId = this.serviceOrgIdProp
			this.searchData.serviceOldPersonId = this.serviceOldPersonIdProp
			
			console.log(this.serviceOldPersonIdProp,this.serviceOldPersonIdProp!='','serviceOldPersonIdProp');
			// console.log(typeof(this.serviceOrgIdProp),'serviceOrgIdProp');
			// console.log(typeof(this.serviceWorkPersonIdProp),'serviceWorkPersonIdProp');


			this.initView()
		},
		methods: {
			...mapActions([
				'queryClearningList',
				// "getServeRecordsListServe",
				"delServeRecordsServe",
				'queryCleanList',
				'deleteClean'
			]),
			initView() {
                console.log('aaaaaaaaaaaaaaaaaaaaaaaaaa');
				this.getLists();
			},
			// 是否显示查询功能
			showSearch(bool) {
				this.seachStatus = bool;
			},
			goPage(type, itemData) {
				console.log(123);
				if(type == 'personDetail') {
					this.$router.push({
						name: 'AddPerson',
						params: {
							type: 'detail',
							id: itemData.serviceOldPersonId
						}
					})
				}else{
					this.$router.push({
						name: 'AddCleaningRecords',
						params: {
							type: type,
							id: type == 'add' ? 'add' : itemData.id
						},
						query: {
							item: type == 'add' ? 'add' : JSON.stringify(itemData)
						}
					})
				}
			},
			goPageDetai(type, itemData){
				this.$router.push({
					name: 'AddCleaningRecords',
					params: {
						type: type,
						id: type == 'add' ? 'add' : itemData.id
					},
					query: {
						item: type == 'add' ? 'add' : JSON.stringify(itemData)
					}
				})
			},
			
			callBack(itemData){
				console.log(itemData);
					this.$router.push({
						name: 'AddRevisit',
						params: {
							id: itemData.id
							
						},
						query: {
							id: itemData.id,
							revisitStart:itemData.revisitStart
						}
					})
			},
			getLists() {
				this.loading = true;
				this.queryCleanList({
						pageNum: this.tablePage.currentPage,
						pageSize: this.tablePage.pageSize,
					
				}).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.dataList;
						this.tablePage.totalResult = +res.data.total;
						for (let i = 0; i < this.tableData.length; i++) {
     						this.$set(this.tableData[i],'ismore',false)
   }
						// this.tableData.map(item=>{
						// 	item.ismore = 'false'
						// })
						console.log(this.tableData)


					}
					this.loading = false;
				})
			},
			// 分页功能
			pageChange(item) {
				if (item.type == "size") {
					this.tablePage.currentPage = 1;
				} else {
					this.tablePage.currentPage = item.currentPage;
				}
				this.getLists();
			},
			searchEvent() {
				console.log(this.searchData);
				this.seachStatus = false;
				this.getLists();
			},
			searchReset() {
				this.searchData = {};
				this.getLists();
			},
			remove(id) {
				this.$XModal.confirm("您确定要删除该数据?").then(type => {
					if (type === "confirm") {
						console.log('执行删除操作');
						this.delete(id)
					}
				})
			},
			delete(id) {
				this.deleteClean({
					id: id
				}).then(res => {
					if (res.code == 200) {
						this.$XModal.message({
							message: '删除成功',
							status: "success"
						});
						this.getLists()
					}
					this.loading = false;
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.wrapper{
		height: 100%;
		display: flex;
		flex-direction: column;
		.scroll-box{
			flex: 1;
			overflow: auto;
		}
	  .datetime{
	    display: flex;
	    align-items: center;
	    span{
	      margin: 0 3px;
	    }
	  }
	}
	.sign i {
		margin-right: 3px;
		display: inline-block;
		width: 10px;
		height: 10px;
		border-radius: 50%;
	}
	.vxe-button.type--button {
		    border: 1px solid #f2f4ff9e !important;
	}
</style>
