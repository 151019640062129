<template>
  <div class="wrapper">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="基本信息" name="first">
        <base-info></base-info>
      </el-tab-pane>
        <el-tab-pane label="资产登记" name="eleven">
        <assets-record v-if="isAssets"></assets-record>
      </el-tab-pane>
      <el-tab-pane label="员工信息" name="second">
        <correspondence-list></correspondence-list>
      </el-tab-pane>
      <el-tab-pane label="消杀记录" name="twelve">
       <cleaning-record v-if="isCleaning"></cleaning-record>
      </el-tab-pane>
      <!-- <el-tab-pane label="消毒" name="thirteen">
        <disinfect-record v-if="isdisinfect"></disinfect-record>
      </el-tab-pane> -->
      <!-- <el-tab-pane label="消杀记录" name="thirteen">
        <disinfect-record v-if="isdisinfect"></disinfect-record>
      </el-tab-pane> -->
      <el-tab-pane label="核心服务" name="third">
        <cn-core-service></cn-core-service>
      </el-tab-pane>
      <el-tab-pane label="服务记录" name="fourth">
        <serviceRecord :serviceOrgIdProp = serviceOrgId v-if="isrecord"></serviceRecord>
      </el-tab-pane>
      <el-tab-pane label="服务对象" name="five">
        <service-object></service-object>
      </el-tab-pane>
      <el-tab-pane label="入住老人" name="six">
        <resident-elderly></resident-elderly>
      </el-tab-pane>
      <el-tab-pane label="老人签到" name="seven">
        <personSign ref="personSign" :personSignType = 1></personSign>
      </el-tab-pane>
      <el-tab-pane label="员工签到" name="eight">
        <personSign ref="personSign" :personSignType = 2>
        </personSign>
      </el-tab-pane>
      <el-tab-pane label="运营日报" name="nine">
        <dailyNewspaper ref="dailyNewspaper" v-if="isdailyNewspaper">
        </dailyNewspaper>
      </el-tab-pane>
      <el-tab-pane label="活动记录" name="ten">
       <activity-record v-if="isActiveRecord"></activity-record>
      </el-tab-pane>
      
    </el-tabs>
  </div>
</template>
<script>
import baseInfo from './baseInfo.vue'
import cnCoreService from '../cnCoreSerive/index.vue'
import activityRecord from '../../../HomeCare/ServeOrgs/components/activityRecord.vue'
import serviceRecord from '../../../HomeCare/ServeRecords/list.vue'
import correspondenceList from '../correspondenceList/index.vue'
import serviceObject from '../serviceObject/index.vue'
import residentElderly from '../residentElderly/index.vue'
import personSign from '../personSign/index.vue'
import dailyNewspaper from '../dailyNewspaper/index.vue'
import assetsRecord from '../assetsRecord/index.vue'
import cleaningRecord from '../cleaningRecord/index.vue'
import disinfectRecord from '../disinfectRecord/index.vue'






export default {
  beforeRouteEnter (to, from, next) {
  next(vm => {
    if(from.name == 'addActivityRecord'){
      vm.activeName ='ten'
      vm.isActiveRecord = true
    }else if(from.name == 'AddCleaningRecords'){
      vm.activeName ='twelve'
      vm.isCleaning = true
    }else if(from.name == 'AddSisinfectRecords'){
      vm.activeName ='thirteen'
      vm.isdisinfect = true
    }
    // 通过 `vm` 访问组件实例
  })
},
  name: 'Detail',
  components: {activityRecord, baseInfo, cnCoreService, serviceRecord, correspondenceList, serviceObject, residentElderly,personSign,dailyNewspaper,assetsRecord,cleaningRecord,disinfectRecord},
  data() {
    return {
      activeName: 'first',
      isSeven:false,
      personSignType:1,  //签到类型 1老人签到 2 从业人员签到
      isdailyNewspaper:false,
      isrecord:false,
      serviceOrgId:(JSON.parse(this.$route.query.item)).id,
      isActiveRecord:false,
      isAssets:false,
      isCleaning:false,
      isdisinfect:false

    }
  },
  computed: {},
  created() {
    console.log((JSON.parse(this.$route.query.item)).id);
  },
  methods: {
    handleClick(tab,event) {
      if(tab.name=='nine'){
        this.isdailyNewspaper = true
        this.isCleaning = false
        this.isrecord = false
        this.isActiveRecord = false
        this.isAssets = false
        this.isdisinfect = false

      }else if(tab.name=='fourth'){
        this.isrecord = true
      }else if(tab.name=='ten'){
        this.isActiveRecord = true
        this.isdailyNewspaper = false
        this.isCleaning = false
        this.isrecord = false
        this.isAssets = false
        this.isdisinfect = false

      }else if(tab.name=='eleven'){
        this.isAssets = true
        this.isdailyNewspaper = false
        this.isCleaning = false
        this.isrecord = false
        this.isActiveRecord = false
        this.isdisinfect = false
      }else if(tab.name=='twelve'){
        this.isCleaning = true
        this.isdailyNewspaper = false
        this.isrecord = false
        this.isActiveRecord = false
        this.isAssets = false
        this.isdisinfect = false

      }else if(tab.name=='thirteen'){
        this.isdisinfect = true
        this.isdailyNewspaper = false
        this.isCleaning = false
        this.isrecord = false
        this.isActiveRecord = false
        this.isAssets = false
      }else{
        this.isdailyNewspaper = false
        this.isCleaning = false
        this.isrecord = false
        this.isActiveRecord = false
        this.isAssets = false
        this.isdisinfect = false

      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  overflow-y:auto;
  .m-content {
    .mc-item {
      line-height: 30px;
      span {
        display: inline-block;
        width: 150px;
        text-align: right;
      }
    }
  }
  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }
}
</style>
