<template>
  <!-- 运营日报 -->
  <div class="wrapper">
      <div class="main">
        <el-table
        stripe
        :data="tableData"
        >
      <el-table-column
        type="index"
        align="center"
        label="序号"
        >
      </el-table-column>
       <el-table-column
        prop="date"
        label="时间"
        align="center"
        >
      </el-table-column>
      <el-table-column
        prop="enterprise"
        label="运营企业"
        align="center"
        >
      </el-table-column>
      <el-table-column
        label="开/关门时间"
        align="center"
        >
        <template slot-scope="scope">
          <p  style="color:red">{{ scope.row.openTime|timeFilter}}</p>
          <!-- <p  style="color:#13AD05">{{ scope.row.signTimeFrom|timeFilter}}</p> -->

          <p style="color:#13AD05">{{ scope.row.closeTime|timeFilter}}</p>
        </template>

      </el-table-column>
      <el-table-column
        prop="receptionNum"
        label="接待人次"
        align="center"
        show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="serviceTimes"
        label="服务次数"
        align="center"
        show-overflow-tooltip>
      </el-table-column>

      <el-table-column
        prop="remarks"
        label="备注"
        align="center">
      </el-table-column>
       <el-table-column
      label="操作"
      align="center">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small" disabled>查看详情</el-button>
      </template>
    </el-table-column>
    </el-table>
    </div>
      <div style="text-align: right; margin-top: 10px">
        <el-pagination
          background
          @size-change="handlePersonSizeChange"
          @current-change="handlePersonCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="total,jumper,prev, pager, next,sizes"
          :total="totalResult"
        ></el-pagination>
      </div>
  </div>
</template>

<script>
import {mapActions } from 'vuex'

export default {
  name:'personSign',
  filters:{
    timeFilter(row){
      let time = ''
      if(row!=null){
        time = row.substring(0,row.lastIndexOf(":"))
      return time
      }
      
    }
  },

  created(){
    console.log(123);
    // console.log('personSign');
    this.getList()

  },
  data(){
    return {
      tableData:[],
        totalResult: 0,
        currentPage: 1,
        pageSize: 10,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
    }
  },
  methods:{
      ...mapActions([
      'cmOperationDailyList' //运营日报列表接口
      

    ]),
    //老人打卡列表
    getList(){
        let param = {
          'sceneId':this.$route.params.id,
          'pageNum':this.currentPage,
          'pageSize':this.pageSize,
          'scene':JSON.parse(this.$route.query.item).orgType
        }
        this.cmOperationDailyList(param).then((res) => {
          if (res.code == 200) {
            this.tableData = res.data.records
            this.totalResult = Number(res.data.total)
            // console.log(res,'res')
            
          }
        })
      
    },
    handlePersonCurrentChange(val){
      this.currentPage = val
      this.getList()
    },
    handlePersonSizeChange(val){
      this.pageSize = val
      this.getList()
      
    },
    handleClick(){}
  }
}
</script>

<style lang="scss" scoped>
.header{
  // height: 50%;
  // width: 100%;
  padding-bottom: 10px;
  border-bottom: solid 1px #e4e7ed;
  margin-bottom: 10px;
}
.el-table .cell, .el-table th div {
	padding-right: 0;
}
</style>>
