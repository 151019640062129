<template>
  <!-- 资产登记 -->
  <div class="wrapper">
      <div class="header">
        <el-row :gutter="20">
          <el-col :span="3">
            <el-button type="primary" icon="el-icon-plus" size='medium' @click="add">添加</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="main">
        <el-table
        stripe
        :data="tableData"
        >
      <el-table-column
        type="index"
        align="center"
        label="序号"
        >
      </el-table-column>
       <el-table-column
        prop="assetName"
        label="物品名称"
        align="center"
        >
      </el-table-column>
      <el-table-column
        prop="assetCount"
        label="数量"
        align="center"
        >
      </el-table-column>
      <el-table-column
        prop="assetManager"
        label="资产管理人"
        align="center">
      </el-table-column>
      <el-table-column
        prop="remark"
        label="备注"
        align="center">
      </el-table-column>
       <el-table-column
      label="操作"
      align="center">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row)" type="text" size="small">修改</el-button>
        <el-button type="text" size="small" @click="delClick(scope.row)">删除</el-button>
      </template>
    </el-table-column>
    </el-table>
    </div>
      <div style="text-align: right; margin-top: 10px">
        <el-pagination
          background
          @size-change="handlePersonSizeChange"
          @current-change="handlePersonCurrentChange"
          :current-page="currentPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="total,jumper,prev, pager, next,sizes"
          :total="totalResult"
        ></el-pagination>
      </div>


         <el-dialog  title="添加资产" :visible="dialogTableVisible" :show-close='false' :close-on-press-escape='false' width="50%"  >
                <el-form :model="form" label-width="auto" class="demo-ruleForm" label-position="right" size="small" ref="form"  :rules="rules">
                <el-form-item label="物品名称:"  prop="assetName" >
                    <el-input v-model="form.assetName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="资产数量:"  prop="assetCount">
                    <el-input v-model="form.assetCount" @input="(v)=>(form.assetCount=v.replace(/[^\d]/g,''))" autocomplete="off"></el-input>
                </el-form-item>
                 <el-form-item label="资产管理人："  prop="assetManager">
                    <el-input v-model="form.assetManager" autocomplete="off"></el-input>
                </el-form-item>
                 <el-form-item label="备注：" prop="remark" >
                    <el-input v-model="form.remark" autocomplete="off"></el-input>
                </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer" style="text-align: center;">
                    <el-button type="primary" @click="commitBtn('form')" size="medium">确 定</el-button>
                    <el-button @click="closeDialog('form')" size="medium">取 消</el-button>
                </div>
             </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
    name:'assetsRecord',
    data(){
        return {
            form:{
                id:'',
                cmId:this.$route.params.id,
                assetName:'',
                assetCount:'',
                assetManager:'',
                remark:''
            },
            isEdit:false,
            tableData:[],
            totalResult: 0,
            currentPage: 1,
            pageSize: 10,
            align: 'left',
            pageSizes: [10, 20, 50, 100, 500, 1000],
            dialogTableVisible:false,
              rules:{
                assetCount:[ { required: true, message: '请输入资产数量', trigger: 'blur' }],
                assetManager:[ { required: true, message: '请输入管理人', trigger: 'blur' }],
                assetName:[ { required: true, message: '请输入资产名称', trigger: 'blur' }],

            }
        }
    },
    created(){
        this.getList()
        console.log(this.$route.params.id);
    },
    methods:{
         ...mapActions([
          "queryAssetList",
          'addAssset',
          'updateAssset',
          'delAsset'
        ]),
        getList(){
            let params = {
                cmId:this.$route.params.id,
				pageNum: this.currentPage,
				pageSize: this.pageSize,
            }
            this.queryAssetList(params).then(res=>{
                console.log(res);
                if(res.code==200){
                    this.tableData = res.data.dataList
                    this.totalResult = res.data.total
                }
            })
        },
        handlePersonCurrentChange(val){
            this.currentPage = val
            this.getList()
        },
        handlePersonSizeChange(val){
            this.pageSize = val
            this.getList()
        
        },
        add(){
            this.dialogTableVisible = true
            this.form.assetName =''
            this.form.assetCount = ''
            this.form.assetManager = ''
            this.form.remark = ''
            this.form.id = ''
            this.isEdit =false
        },
        //确认添加
        commitBtn(formName){
            this.$refs[formName].validate((valid) => {
            if (valid) {
                let params = this.form
                if(this.isEdit==false){
                    this.addAssset(params).then(res=>{
                        console.log(res);
                        if(res.code ==200){
                            this.$message.success('添加成功')
                            this.dialogTableVisible= false
                            this.$refs[formName].resetFields();
                            this.getList()
                        }
                    })
                }else{
                    this.updateAssset(params).then(res=>{
                        console.log(res);
                        if(res.code ==200){
                            this.$message.success('修改成功')
                            this.dialogTableVisible= false
                            this.$refs[formName].resetFields();
                            this.getList()
                        }
                    })
                    
                }

            } else {
                console.log('error submit!!');
                return false;
            }
            });
        },
        //修改
        handleClick(row){
            this.dialogTableVisible = true
            this.isEdit= true
            this.form. assetName = row.assetName
            this.form.assetCount = row.assetCount
            this.form.assetManager = row.assetManager
            this.form.remark = row.remark
            this.form.id = row.id
        },
        closeDialog(formName){
            this.$refs[formName].resetFields();
            this.dialogTableVisible= false
            // this.form.assetName =''
            // this.form.assetCount = ''
            // this.form.assetManager = ''
            // this.form.remark = ''
        },
        //删除
        delClick(row){
            let params = {
                id:row.id
            }
            this.delAsset(params).then(res=>{
                if(res.code==200){
                    this.$message.success('删除成功')
                    this.getList()
                }
            })


        }

    }
}
</script>

<style>

</style>